import { mapActions } from "vuex";

export default {
  computed: {
    points() {
      return this.$store.state.points.points;
    },
  },
  methods: {
    ...mapActions("points", ["getPoints"]),
  },
  mounted() {
    this.getPoints();
  },
};